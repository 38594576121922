import React, { Component } from 'react';
import Layout from '../layout/layout';
import axios from 'axios';
import { Link, navigate } from 'gatsby';
import resultGroups from '../components/internal/result-groups';
import searchIcon from '../images/icons/explore-search.png';
import arrowIcon from '../images/icons/icon-arrow.svg';
import iconLocation from '../images/icons/icon-location.svg';
import iconWebinar from '../images/icons/icon-webinar.svg';
import iconManWithMagnifyingGlass from '../images/icons/illustration-magnifying-glass.svg';
import iconLittleTrees from '../images/icons/illustration-trees.svg';
import { deepCloneArray } from '../utils/deep-clone';
import capitalize from '../utils/capitalize';
import { pluralize } from '../utils/pluralize';
import { SEARCH } from '../constants/url';

export default class SearchResults extends Component {
    state = {
        searchQuery: '',
        searchResults: {
            schools: [],
            pages: [],
            faqApplicants: [],
            faqRecommenders: [],
            blogPosts: [],
            events: []
        },
        schools: 0,
        pages: 0,
        faqApplicants: 0,
        faqRecommenders: 0,
        blogPosts: 0,
        events: 0,
        inProgress: true,
        resultsTotal: 0
    };

    UNSAFE_componentWillMount = () => {
        if (typeof window !== 'undefined') {
            const queryString = decodeURI(window.location.search.split('?q=').pop());
            this.setState({ searchQuery: queryString }, () => this.search());
        }
    };

    UNSAFE_componentWillReceiveProps = () => {
        if (typeof window !== 'undefined') {
            const queryString = decodeURI(window.location.search.split('?q=').pop());
            this.setState({ searchQuery: queryString }, () => this.search());
        }
    };

    handleChange = event => this.setState({ searchQuery: event.target.value });

    getResultsByType (results) {
        const schools = [];
        const pages = [];
        const faqApplicants = [];
        const faqRecommenders = [];
        const blogPosts = [];
        const events = [];
        
        results.forEach(resultItem => {
            switch (resultItem.type) {
                case 'school':
                    schools.push(resultItem);
                    break;
                case 'text_page':
                    pages.push(resultItem);
                    break;
                case 'faq_article_applicant':
                    faqApplicants.push(resultItem);
                    break;
                case 'faq_article_recommender':
                    faqRecommenders.push(resultItem);
                    break;
                case 'blog_post':
                    blogPosts.push(resultItem);
                    break;
                case 'event':
                    events.push(resultItem);
                    break;
                default:
                    break;
            }
        });

        return { schools, pages, faqApplicants, faqRecommenders, blogPosts, events };
    }

    loadInitialSchoolResults (schools) {
        const intialSchoolBatchSize = schools.length > 6 ? 6 : schools.length;
        let numberLoaded = 0;
        for (let i = 0; i < intialSchoolBatchSize; i++) {
            schools[i].isLoaded = true;
            numberLoaded++;
        }
        this.setState({schools: numberLoaded});
    }

    loadInitialPageResults (pages) {
        const intialPageBatchSize = pages.length > 5 ? 5 : pages.length;
        let numberLoaded = 0;
        for (let i = 0; i < intialPageBatchSize; i++) {
            pages[i].isLoaded = true;
            numberLoaded++;
        }
        this.setState({pages: numberLoaded});
    }

    loadInitialFaqApplicantResults (faqApplicants) {
        const intialPageBatchSize = faqApplicants.length > 5 ? 5 : faqApplicants.length;
        let numberLoaded = 0;
        for (let i = 0; i < intialPageBatchSize; i++) {
            faqApplicants[i].isLoaded = true;
            numberLoaded++;
        }
        this.setState({faqApplicants: numberLoaded});
    }

    loadInitialFaqRecommenderResults (faqRecommenders) {
        const intialPageBatchSize = faqRecommenders.length > 5 ? 5 : faqRecommenders.length;
        let numberLoaded = 0;
        for (let i = 0; i < intialPageBatchSize; i++) {
            faqRecommenders[i].isLoaded = true;
            numberLoaded++;
        }
        this.setState({faqRecommenders: numberLoaded});
    }

    loadInitialBlogPostResults (blogPosts) {
        const intialBlogPostBatchSize = blogPosts.length > 5 ? 5 : blogPosts.length;
        let numberLoaded = 0;
        for (let i = 0; i < intialBlogPostBatchSize; i++) {
            blogPosts[i].isLoaded = true;
            numberLoaded++;
        }
        this.setState({blogPosts: numberLoaded});
    }

    loadInitialEventResults (events) {
        const intialEventBatchSize = events.length > 5 ? 5 : events.length;
        let numberLoaded = 0;
        for (let i = 0; i < intialEventBatchSize; i++) {
            events[i].isLoaded = true;
            numberLoaded++;
        }
        this.setState({events: numberLoaded});
    }

    loadInitialResults (results) {
        const { schools, pages, faqApplicants, faqRecommenders, blogPosts, events } = this.getResultsByType(results);

        this.loadInitialSchoolResults(schools);
        this.loadInitialPageResults(pages);
        this.loadInitialFaqApplicantResults(faqApplicants);
        this.loadInitialFaqRecommenderResults(faqRecommenders);
        this.loadInitialBlogPostResults(blogPosts);
        this.loadInitialEventResults(events);
        
        return { schools, pages, faqApplicants, faqRecommenders, blogPosts, events };
    }

    storeResults (results) {
        const { schools, pages, faqApplicants, faqRecommenders, blogPosts, events } = this.loadInitialResults(results.data);

        this.setState({
            inProgress: false,
            searchResults: { schools, pages, faqApplicants, faqRecommenders, blogPosts, events },
            resultsTotal: results.total
        });
    }

    getResults (queryString) {
        return axios
            .get(`${window.backendUrl}${SEARCH}/?q=${queryString}`)
            .then(response => {
                if (response.status === 200) {
                    return response.data;
                }
            });
    }

    search () {
        const queryString = encodeURI(this.state.searchQuery);
        this.getResults(queryString).then(results => this.storeResults(results));
    }

    onSubmit (event) {
        event.preventDefault();
        navigate(`/search/?q=${encodeURI(this.state.searchQuery)}`);
    }

    renderHeader () {
        return (
            <form className="site-wide-search-header" onSubmit={event => this.onSubmit(event)}>
                <div className="site-wide-search-input-wrapper">
                    <img src=/{searchIcon} alt="Magnifying glass."/>
                    <input
                        placeholder="Transfer applications"
                        value={this.state.searchQuery}
                        onChange={event => this.handleChange(event)} />
                </div>
                <div className="search-button-container">
                    <button>Search</button>
                </div>
            </form>
        );
    }

    renderResultTotal () {
        return (
            <span className="result-total">
                { `${this.state.resultsTotal} results have matched your search` }
            </span>
        );
    }

    loadedResultNumber(resultGroup) {
        switch (resultGroup) {
            case 'schools':
                return this.state.schools;
            case 'pages':
                return this.state.pages;
            case 'faqApplicants':
                return this.state.faqApplicants;
            case 'faqRecommenders':
                return this.state.faqRecommenders;
            case 'blogPosts':
                return this.state.blogPosts;
            case 'events':
                return this.state.events;
            default:
                break;
        }
    }

    loadMore (resultGroup) {
        const loadedResults = deepCloneArray(this.state.searchResults[resultGroup]);
        let getLodadedResultNumber = this.loadedResultNumber(resultGroup);

        let newTotalOfResults = getLodadedResultNumber;
        for (let i = getLodadedResultNumber; i < getLodadedResultNumber + 9; i++) {
            if(loadedResults[i]) {
                loadedResults[i].isLoaded = true;
                newTotalOfResults++;
            } else {
                break;
            }
        }
        this.setState({ [resultGroup]: newTotalOfResults, searchResults: Object.assign({}, this.state.searchResults, { [resultGroup]: loadedResults }) });
    }

    renderLoadMoreButton (resultGroup) {
        return (
            <div className="load-more-button-container">
                <button onClick={() => this.loadMore(resultGroup)}>
                    <span>Load more results</span>
                    <img src=/{arrowIcon} alt="Arrow tip." />
                </button>
            </div>
        );
    }

    renderWebinarSign () {
        return (
            <>
                <img src=/{iconWebinar} alt="Stylized desktop computer."/>
                <span>Webinar</span>
            </>
        );
    }

    renderLocation (location) {
        return  location ? 
            (<>
                <img src=/{iconLocation} alt="Location marker." />
                <span>{location}</span>
            </>) :
            null;
    }

    renderLocationDetails (eventType, location) {
        return eventType === 'Webinar' ?
            this.renderWebinarSign() :
            this.renderLocation(location);
    }

    renderResults (resultGroup) {
        return (
            <section>
                <div className="site-wide-results-header">
                    <h2>{capitalize(pluralize(resultGroup.name))}</h2>
                    { resultGroup.icon }
                </div>
                <ul className={`site-wide-results-list--${resultGroup.storeKey}`}>
                    {
                        [...this.state.searchResults[resultGroup.storeKey]]
                            .filter(resultItem => resultItem.isLoaded)
                            .sort((current, next) => current.score > next.score ? -1 : 1)
                            .map((resultItem, index) => resultGroup.renderResultItem.call(this, resultItem, index))
                    }
                </ul>
                {
                    this.state.searchResults[resultGroup.storeKey].some(resultItem => !resultItem.isLoaded) ?
                        this.renderLoadMoreButton(resultGroup.storeKey) :
                        null
                }
            </section>
        );
    }

    renderAllResults () {
        return (
            <main>
                { this.renderResultTotal() }
                { this.state.searchResults.schools.length ? this.renderResults(resultGroups.school) : null }
                { this.state.searchResults.pages.length ? this.renderResults(resultGroups.page) : null }
                { this.state.searchResults.faqApplicants.length ? this.renderResults(resultGroups.faqApplicant) : null }
                { this.state.searchResults.faqRecommenders.length ? this.renderResults(resultGroups.faqRecommender) : null }
                { this.state.searchResults.blogPosts.length ? this.renderResults(resultGroups.blogPost) : null }
                { this.state.searchResults.events.length ? this.renderResults(resultGroups.event) : null }
            </main>
        );
    }

    renderNoResults () {
        return (
            <main className="no-results">
                <h2>Sorry, we can't find what you're looking for.</h2>
                <div className="helpful-pages-list-container">
                    <span>Here are some pages that might help:</span>
                    <ul>
                        <li>
                            <Link to="/explore">Explore colleges</Link>
                        </li>
                        <li>
                            <Link to="/blog">Read the blog</Link>
                        </li>
                        <li>
                            <Link to="/events">View Upcoming Events</Link>
                        </li>
                    </ul>
                </div>
                <p>Need additional help? Visit out <Link to="/contact">Support page.</Link></p>
                <div className="illustration-container">
                    <img src=/{iconManWithMagnifyingGlass} alt="Illustration of a man with a giant magnifying glass." />
                    <img src=/{iconLittleTrees} alt="Illustration of two little deciduous trees." />
                </div>
            </main>
        );
    }

    render () {
        return (
            <Layout invertHeader={true}>
                <section className="site-wide-search-page">
                    { this.renderHeader() }
                    <>
                        {
                            this.state.inProgress ?
                                <div className="lds-dual-ring"></div> :
                                this.state.resultsTotal ? this.renderAllResults() : this.renderNoResults()
                        }
                    </>
                </section>
            </Layout>
        );
    }
}
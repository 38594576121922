import React from 'react';
import { Link } from 'gatsby';
import campusIcon from '../../images/icons/icon-campus.svg';
import scrollIcon from '../../images/icons/icon-scroll.svg';
import penIcon from '../../images/icons/icon-pencil.svg';
import calendarIcon from '../../images/icons/icon-calendar2.svg';
import moment from 'moment';
import schoolFallbackImage from '../../images/school-fallback-image.svg';

const resultGroups = {
        school: {
        name: 'College',
        storeKey: 'schools',
        icon: <img src=/{campusIcon} alt="Stylized college campus." />,
        renderResultItem: (resultItem, index) => 
            <li key={index} className="school-grid-item">
                <div className="school-image-wrapper">
                    <img src=/{(typeof resultItem.details.school_image !== "string") ? schoolFallbackImage : resultItem.details.school_image} alt="School" />
                </div>
                <div className="school-info-container">
                    <div className="school-name">
                        <Link to={resultItem.alias}>
                            {resultItem.title}
                        </Link>
                    </div>
                    <div className="school-location">
                        {resultItem.details.address}
                    </div>
                </div>
            </li>
    },
    page: {
        name: 'Page',
        storeKey: 'pages',
        icon: <img src=/{scrollIcon} alt="Stylized scroll." />,
        renderResultItem: (resultItem, index) =>
            <li key={index}>
                <Link to={resultItem.alias}>
                    {resultItem.title}
                </Link>
            </li>
    },
    faqApplicant: {
        name: 'Applicant Help Article',
        storeKey: 'faqApplicants',
        icon: <img src=/{scrollIcon} alt="Stylized scroll." />,
        renderResultItem: (resultItem, index) =>
            <li key={index}>
                <a href=/{`https:/appsupport.commonapp.org/applicantsupport/s/article/${resultItem.details.url_name}`} target="_blank" rel="noreferrer noopener">
                    {resultItem.title}
                </a>
            </li>
    },
    faqRecommender: {
        name: 'Recommender Help Article',
        storeKey: 'faqRecommenders',
        icon: <img src=/{scrollIcon} alt="Stylized scroll." />,
        renderResultItem: (resultItem, index) =>
            <li key={index}>
                <a href=/{`https:/recsupport.commonapp.org/recommendersupport/s/article/${resultItem.details.url_name}`} target="_blank" rel="noreferrer noopener">
                    {resultItem.title}
                </a>
            </li>
    },
    blogPost: {
        name: 'News and update',
        storeKey: 'blogPosts',
        icon: <img src=/{penIcon} alt="Stylized pen." />,
        renderResultItem: (resultItem, index) =>
            <li key={index}>
                <div className="blog-post-info-container">
                    <Link to={resultItem.alias}>
                        {resultItem.title}
                    </Link>
                    <p>{resultItem.details.blog_summary}</p>
                </div>
                <div className="blog-post-image-wrapper">
                    /{(typeof resultItem.details.blog_image !== "string") ? null : <img src=/{resultItem.details.blog_image} alt="Event." /> }
                </div>
            </li>
    },
    event: {
        name: 'Event',
        storeKey: 'events',
        icon: <img src=/{calendarIcon} alt="Stylized calendar." />,
        // intentionally using regular instead of arrow function syntax to make the function boundable
        // keyword this needs to point to the component from which .renderResultItem() was called in
        renderResultItem: function (resultItem, index) {
            return (
                <li key={index}>
                    <div className="event-info-container">
                        <Link to={resultItem.alias}>
                            {resultItem.title}
                        </Link>
                        <span className="event-date">
                            {`${moment(resultItem.details.event_start).format('DD MMM')} 鈥� ${moment(resultItem.details.event_end).format('DD MMM')}`}
                        </span>
                        <p>{resultItem.details.event_summary}</p>
                        <div className="event-location">
                            { this.renderLocationDetails(resultItem.name, resultItem.details.event_location_short) }
                        </div>
                    </div>
                    <div className="blog-post-image-wrapper">
                        /{(typeof resultItem.details.event_image !== "string") ? null : <img src=/{resultItem.details.event_image} alt="Event." /> }
                    </div>
                </li>
            );
        }            
    }
};

export default resultGroups;
